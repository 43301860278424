"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var ngx_device_detector_1 = require("ngx-device-detector");
var SeeUpdateDataComponent = /** @class */ (function () {
    function SeeUpdateDataComponent(datePipe, deviceService) {
        this.datePipe = datePipe;
        this.deviceService = deviceService;
        /** @description Variable to maintain order of Map while looping using ngFor */
        this.originalOrder = function (a, b) {
            return 0;
        };
    }
    SeeUpdateDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log("this.seeUpdatesData--", this.seeUpdatesData);
        Object.keys(this.seeUpdatesData).forEach(function (val) {
            if (val.replace(/[0-9]/g, '') === 'picked') {
                delete _this.seeUpdatesData[val];
            }
        });
    };
    SeeUpdateDataComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (!this.isSeeUpdatesOpen) {
            if (!this.deviceService.isDesktop()) {
                setTimeout(function () {
                    _this.currentindex = null;
                }, 350);
            }
            else
                this.currentindex = null;
        }
    };
    /** @description Method to format time*/
    SeeUpdateDataComponent.prototype.replaceTime = function (time) {
        time = time.replace(" ", "T");
        return this.datePipe.transform(time, " h:mm a");
    };
    /** @description Method to format date*/
    SeeUpdateDataComponent.prototype.replaceDay = function (date) {
        date = date.replace(" ", "T");
        return this.datePipe.transform(date, " EEE, dd MMM");
    };
    /** @description Method to remove numbers from status*/
    SeeUpdateDataComponent.prototype.formatStatus = function (status) {
        return status.replace(/[0-9]/g, '');
    };
    SeeUpdateDataComponent.prototype.reverseStatus = function (status) {
        return status.slice().reverse();
    };
    /** @description Method to return Status */
    SeeUpdateDataComponent.prototype.getStatus = function (status, timeline, actionCode) {
        status = status.replace(/[0-9]/g, '');
        if (status == "created") {
            if (timeline == "forward") {
                return "Order placed";
            }
            else if (timeline == "reverse") {
                return "Return created";
            }
        }
        else if (status == "order_placed" && timeline == "forward") {
            return "Order placed";
        }
        else if (status == "shipment_held" && timeline == "forward") {
            return "Shipment held";
        }
        else if (status == "accepted") {
            return "Order accepted";
        }
        else if (status == "manifested") {
            return "Order manifested";
        }
        else if (status == "packed") {
            return "Order packed";
        }
        else if ((status == "picked" || status == "picked_up") &&
            timeline == "forward") {
            return "Picked up";
        }
        else if (status == "shipped") {
            return "Order shipped";
        }
        else if (status == "ready_to_ship") {
            return "Order is ready to ship";
        }
        else if (status == "dispatched") {
            return "Order dispatched";
        }
        else if (status == "intransit") {
            if (timeline == "reverse")
                return "Return intransit";
            else
                return "Order intransit";
        }
        else if (status == "shipment_delayed") {
            return "Shipment has been delayed";
        }
        else if (status == "delivered") {
            if (timeline == "reverse")
                return "Return delivered";
            else
                return "Order delivered";
        }
        else if (status == "out_for_delivery") {
            if (timeline == "reverse")
                return "Return is out for delivery";
            else
                return "Order is out for delivery";
        }
        else if (status == "rto" || status == "cancelled") {
            return "Order cancelled";
        }
        else if (status == "rto_intransit") {
            return "Return In-transit";
        }
        else if (status == "rto_created") {
            return "Return created";
        }
        else if (status == "rto_delivered_warehouse") {
            return "Return delivered to warehouse";
        }
        else if (status == "rto_out_for_delivery") {
            return "Return is out for delivery";
        }
        else if (status == "rto_shipment_delay") {
            return "Return shipment is delayed";
        }
        else if (status == "rto_requested") {
            return "Return requested";
        }
        else if (status == "not_serviceable") {
            return "Delivery not serviceable";
        }
        else if (status == "failed_delivery" || status == "delivery_failed") {
            if (status == "failed_delivery" && timeline == "forward") {
                return "Failed delivery attempt";
            }
            else if (status == "failed_delivery" && timeline == "reverse") {
                // return "Failed pickup attempt";
                return "Failed return attempt";
            }
        }
        else if (status == "pickup_pending") {
            return "Pickup pending";
        }
        else if (status == "out_for_pickup") {
            if (timeline == "reverse")
                return "Return out for pickup";
            else
                return "Out for pickup";
        }
        else if (status == "order_placed" && timeline == "reverse") {
            return " Return Order placed";
        }
        else if (status == "approved") {
            return "Return approved";
        }
        else if ((status == "picked" || status == "picked_up") &&
            timeline == "reverse") {
            return "Return picked up";
        }
        else if (status == "pickup_cancelled") {
            if (timeline == "reverse")
                return "Return pickup failed";
            else
                return "Pickup failed";
        }
        else if (status == "return_cancelled") {
            return "Return cancelled";
        }
        else if (status == "delivered_warehouse" ||
            status == "warehouse_delivered") {
            return "Delivered warehouse";
        }
        else if (status == "ndr_resolution_submitted") {
            if (actionCode == 'ACTF001')
                return 'Customer requested another attempt ';
            else if (actionCode == 'ACTF002')
                return 'Customer rescheduled delivery';
            else if (actionCode == 'ACTF003')
                return 'Customer cancelled order delivery';
            else if (actionCode == 'ACTF004')
                return 'Customer requested another attempt';
            else
                return "Delivery requested by customer";
        }
        else if (status == "received") {
            return "Received";
        }
        else if (status == "rto_delivered") {
            return "Return delivered";
        }
        else if (status == "rto_failed") {
            return "Return attempt failed";
        }
        else if (status == "cancelled_order") {
            if (timeline == "reverse")
                return "Return pickup cancelled";
            else
                return "Cancelled order";
        }
        else {
            return status;
        }
    };
    /** @description Method to open particular update section */
    SeeUpdateDataComponent.prototype.updateclick = function (i) {
        if (this.currentindex === i) {
            this.currentindex = null;
        }
        else {
            this.currentindex = i;
        }
    };
    return SeeUpdateDataComponent;
}());
exports.SeeUpdateDataComponent = SeeUpdateDataComponent;
